import axios from "axios";
import Category from "./Models/category.ts";
const baseURL = "https://apii.amantes30.com/template-portfolio";


 export function GetCategoryIDList(setCategoryList){
   
   
    axios.get(baseURL + "/collections")
    .then((response)=>{
         const temp2 = response.data.map((ele) => {
             return new Category(ele.id, ele.title);
           });
          setCategoryList(temp2);
    })
    .catch((err)=>{
        return err;
    })
}
export function SelectCurrentCategory(elementID, setCurrentCategoryId){
      const url2 = baseURL + "/pictures/" + elementID;
      setCurrentCategoryId(url2);
}
export function GetRandomPicturesURL(){
    const url= baseURL + "/pictures";
    return url;    
}

export async function GetPhotos(currentCategoryID, setList){ 
       
    return axios
      .get(currentCategoryID)
      .then((response) => {
        console.log("Success");
        
        const list = response.data.map((element) => {            
            return element;
        });
        setList(list);
      })
      .catch((err) => {
        console.log(err);
      });
      

  }

