import uuid from "react-uuid";

function Footer() {
  return (
    <footer>
     
    </footer>
  );
}
export default Footer;


