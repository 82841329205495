import "../App.css";

import React, { useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";
import  axios  from "axios";
import { useState } from "react";
import LoadScreen from '../Components/LoadScreen'
function Layout({setUser}) {
  document.title = "Portfolio";
  const [isloading, setisloding] = useState(true)
  
  async function getUserProfile() {
    await axios
      .get("https://api.amantes30.com/template-portfolio/profile")
      .then((response) => {
        console.log(response.data)
        setUser(response.data)
        setisloding(false)
      })    
  }
  useEffect(()=>{
    getUserProfile()
  },[])
  
  return (
    <div className="App">
      
      {isloading ? <LoadScreen message={"It may take few seconds until the server start, thanks for your patience"}/> :  ''}
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
