function LoadScreen({message}) {
  return (
    <div className="load-screen">
      <div className="spinner-grow text-success" role="status">       
      </div>
      <span className="text-dark d-block">{message}</span>
    </div>
  );
}
export default LoadScreen;
