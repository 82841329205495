import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import uuid from "react-uuid";
import Typed from "typed.js";

function Carousel({ category }) {
  let list = [];
  category.forEach((element) => {
    category.indexOf(element) === 0
      ? list.push(
          <CarouselItem key={uuid()} element={element} active={"active"} />
        )
      : list.push(<CarouselItem key={uuid()} element={element} />);
  });
  const circleStyle = {
    borderRadius: "50%",
    width: "10px",
    height: "10px",
  };

  return (
    <>
      <div id="my-carousel" className="carousel carousel slide">
        <div className="carousel-indicators ">
          <button
            type="button"
            data-bs-target="#my-carousel"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
            style={circleStyle}
          ></button>
          <button
            type="button"
            data-bs-target="#my-carousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
            style={circleStyle}
          ></button>
        </div>
        <div className="carousel-inner h-100">{list}</div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#my-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#my-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}

export default Carousel;

function CarouselItem({ active, element }) {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [element],
      typeSpeed: 100,
    });
    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <div
      className={
        active === "active"
          ? "carousel-item active h-100"
          : "carousel-item h-100"
      }
      data-bs-interval="10000"
    >
      <img
        loading="lazy"
        src="/eyosias-g-C0obmp0U5cQ-unsplash.jpg"
        className="d-block w-100 h-100 cover-p"
        alt="..."
      />
      <div className="carousel-caption s ">
        <Link
          to={"/" + element.toLowerCase()}
          style={{ textDecoration: "none", color: "white" }}
        >
          <h1 className="category-title" ref={el}></h1>
          {/* <p>Some representative placeholder content for the first slide.</p> */}
        </Link>
      </div>
    </div>
  );
}
