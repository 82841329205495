import uuid from "react-uuid";
import ImageItem from "./ImageItem";

export function ImageView1x1({ linkList }) {
  var firstCol = [];
  for (let i = 0; i < linkList.length; i++) {
    firstCol.push(<ImageItem key={uuid()} url={linkList[i]} />);
  }

  return (
    <>
      <ul
        style={{ height: "auto" }}
        className="picture-list1x1"
      >
        <li className="mygrid_vertical1x1">{firstCol}</li>
      </ul>
    </>
  );
}
export function ImageView3x3({ linkList }) {
  var listElements = document.querySelectorAll(".mygrid_vertical3x3");
  var firstCol = [];
  var secondCol = [];
  var thirdCol = [];

  for (var i = 0; i < linkList.length; i++) {
    switch (i % listElements.length) {
      case 0:
        firstCol.push(<ImageItem key={uuid()} url={linkList[i]} />);
        break;
      case 1:
        secondCol.push(<ImageItem key={uuid()} url={linkList[i]} />);
        break;
      case 2:
        thirdCol.push(<ImageItem key={uuid()} url={linkList[i]} />);
        break;
      default:
        break;
    }
  }

  //console.log(linkList);
  return (
    <>
      <ul style={{ height: "auto" }} className="picture-list">
        <li className="mygrid_vertical3x3">{firstCol}</li>
        <li className="mygrid_vertical3x3">{secondCol}</li>
        <li className="mygrid_vertical3x3">{thirdCol}</li>
      </ul>
    </>
  );
}

function isBottomOfPage(e) {
  return (
    e.target.scrollHeight - e.target.offsetHeight <= e.target.scrollTop + 5
  );
}
