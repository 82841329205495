function ImageItem({ url }) {
  return (
    <div style={{width:"100%"}}>
      <img
        loading="lazy"
        className="placeholder"
        alt={url}
        onLoad={(e) => {
          e.target.classList.remove("placeholder");
        }}
        src={url}
     />
    </div>
  );
}

export default ImageItem;
