import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home/Home";
import PhotosPage from "./pages/Photos/PhotosPage";
import Videos from "./pages/Videos";
import AboutPage from "./pages/About/AboutPage";
import { useState } from "react";

export default function App() {
  const [user, setUser] = useState('')
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout setUser={setUser} />}>
          <Route index element={<Home />} />
          <Route path="/photos" element={<PhotosPage />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/aboutme" element={<AboutPage user={user}/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
