import { closeWindow } from "../functions";

function Header() {
  return (
    <header>
       <h3 style={{fontFamily: "Bavex"}} >Portfolio</h3>
      <ul id="top-nav-links">
        <li>
          <h4>
            <a href="/">Home</a>
          </h4>
        </li>

        <li style={{ display: "flex", flexDirection:"column" }}>
          <h4>
            <a href="/aboutme">About</a>
          </h4>
         
        </li>
      </ul>
      
    </header>
  );
}

export default Header;

export function BootStrapDropDown({CategoyList}) {
  let list = []
  CategoyList.forEach(element => {
    list.push(
      <li>
            <a className="dropdown-item" href="#">
              {element}
            </a>
      </li>
    )
    
  });
  return (
    <>
      <div class="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>
        <ul className="dropdown-menu dropdown-menu-dark">
          <li>
            <a className="dropdown-item active" href="#">
              Action
            </a>
          </li>
          {list}
        </ul>
      </div>
    </>
  );
}
