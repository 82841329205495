import uuid from "react-uuid";
import { GetCategoryIDList, SelectCurrentCategory } from "../../../functions";

import { useEffect, useState } from "react";

function CategoryChoiceList({ category, setCurrentCategoryId }) {
  
  let listOfChoices = [];
  const [categoryList, setCategoryList] = useState([]);
  const [currentWidth, setCurrentHeight] = useState(window.innerWidth);
    window.addEventListener("resize", (event) => {
      setCurrentHeight(window.innerWidth);
    });
  useEffect(() => {
    GetCategoryIDList(setCategoryList);
  }, []);
  categoryList.forEach((element) => {
    listOfChoices.push(
      <li key={uuid()}>
        <button type="button"
          onClick={(e) => {
            e.preventDefault();
            if(currentWidth <= 500){
              document.querySelector(".category-choice-list").classList.toggle("off")
            }
            
            SelectCurrentCategory(element.id, setCurrentCategoryId);
          }}
          className="mbtn"
        >
          {element.title.toUpperCase()}{" "}
        </button>
      </li>
    );
  });
  return (
    <>
      <div className="category-choice-dropdown">
        <button type="button" onClick={ToggleDropDown}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="black"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>
      </div>

      <section className={currentWidth >= 500? "category-choice-list":"category-choice-list off"}>
        <ul>
          <li>
            <h1>CATAGORIES</h1>
            <hr />
          </li>
          {listOfChoices}
        </ul>
      </section>
    </>
  );
}
export default CategoryChoiceList;

function ToggleDropDown(e){
  e.preventDefault();
  document.querySelector(".category-choice-list").classList.toggle("off")
}