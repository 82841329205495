import { useState } from "react";
import Carousel from "./Components/Carousel";
import './home.css'


export default function Home(){
    document.body.style.color = "white";
    const category =["PHOTOS", "VIDEOS"];
    const [isPageOn, setisPageOn] = useState("off");

    setTimeout(()=>{
        setisPageOn("");
        if(document.querySelector("main").classList.contains("photos")){
            document.querySelector("main").classList.remove("photos")
          }
    }, 500)
    return(
        <main className={isPageOn}>       
            <Carousel category={category}/>
        </main>
            
    )
}