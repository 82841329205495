class Category{
    id:String;
    title: String;
    total_photos: String;

    constructor(id:string, title:string, total_photos:string){
        this.id = id;
        this.title = title;
        this.total_photos = total_photos;
    }
}

export default Category;