import { useEffect, useState } from "react";

import "./about.css";
import axios from "axios";

function AboutPage({ user }) {
  const [pageState, setPageState] = useState("off");
  const [imgUrl, setUrl] = useState("");
  //const [user, setUser] = useState('')
  setTimeout(() => {
    setPageState("");
    if (!document.querySelector("main").classList.contains("about")) {
      document.querySelector("main").classList.add("about");
    }
  }, 500);
  useEffect(() => {
    GetRandImg();
    //getUserProfile();
  }, []);
  async function GetRandImg() {
    if (imgUrl === "") {
      await axios
        .get("https://apii.amantes30.com/template-portfolio/pictures/random")
        .then((response) => {
          console.log(response);
          setUrl(response.data);
        })
        .catch((err) => {
          console.log("Error : " + err);
        });
    }
  }

  return (
    <main className={pageState}>
      <div id="about-container">
        <section className="content-wrapper">
          <section className="profile-pic">
            <img src={imgUrl} alt=""></img>
          </section>
          <section className="content">
            <section>
              <p id="bio">
                <div class="container">
                  <h1>About This Site</h1>
                  <p>
                    Welcome to my photographer portfolio template site, a
                    platform designed to showcase the artistic visions and
                    technical prowess of photographers from around the world.
                    Whether you are an aspiring photographer looking for
                    inspiration or a seasoned professional seeking new ideas,
                    this site offers a diverse collection of stunning
                    photography templates to elevate your portfolio.
                  </p>
                  <p>
                    <strong>Please Note:</strong> I do not own any of the
                    pictures featured on this website. All images are the
                    property of randomly selected photographers and are used
                    solely for illustrative purposes within our templates. My
                    goal is to provide a creative and professional foundation
                    for photographers to build their own unique portfolios.
                  </p>
                  <p>
                    Explore, get inspired, and start crafting your perfect
                    photography portfolio with this templates. Thank you for
                    visiting!
                  </p>
                </div>
              </p>
              <ul className="unsplash_status">
                <li>
                  <p>{user.total_photos}</p>
                  <span>Photos</span>
                </li>
                <li>
                  <p>{user.total_likes}</p>
                  <span>Likes</span>
                </li>
                <li>
                  <p>{user.downloads}</p>
                  <span>Downloads</span>
                </li>
                <li>
                  <p>{user.total_collections}</p>
                  <span>Collections</span>
                </li>
              </ul>
            </section>
          </section>
        </section>

        <section className="contact-info-footer">
          <ul className="social-link-list">
            <li>
              <a href="/null">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg>
              </a>
            </li>
            <li>
              <a href="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-tiktok"
                  viewBox="0 0 16 16"
                >
                  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                </svg>
              </a>
            </li>
            <li>
              <a href="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-twitter-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </a>
            </li>
          </ul>
          <div style={{ height: "100%" }}>
            <a href="https://amantes30.com/">
              <img
                alt="amanlogo"
                style={{ objectFit: "cover", height: "100%", width: "170px" }}
                src="/logo.svg"
              ></img>
            </a>
          </div>
        </section>
      </div>
    </main>
  );
}
export default AboutPage;
