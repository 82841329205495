import { useState } from "react";
import { Link } from "react-router-dom";

function PopUpMsg({isShow}){
    const [sty, setsty] = useState("modal fade d-block")
    
    setTimeout(()=>{
        setsty("modal fade d-block show")
    }, 200)
    return(
        <div className={sty} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Sorry for the interruption</h1>
                </div>
                <div className="modal-body">
                    Videos category coming soon!
                </div>
                <div className="modal-footer">
                    <Link className="btn btn-primary"  to={"/"}>Back to HomePage</Link>
                </div>
                </div>
            </div>
        </div>
    )
}

export default PopUpMsg;